import styles from "./MenuLink.module.css";

import { NavLink} from "react-router-dom";

export default function MenuLink({ children, to, onClick }) {

  return (
    
    <NavLink onClick={onClick}
      className={({ isActive, isPending, isTransitioning }) =>
        [
          isPending ? "pending" : "",
          isActive ? styles.linkDestacado : "",
          isTransitioning ? "transitioning" : "",
        ].join(" ")
      }
      to={to}
    >
      {children}
    </NavLink>

  );
}
