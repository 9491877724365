import {useRef} from 'react';
import styles from "./Menu.module.css";
import {FaBars,FaTimes} from "react-icons/fa";

import MenuLink from "../MenuLink";

export default function Menu() {
  const navRef = useRef()
  const openMenu = () => {
    navRef.current.classList.toggle(`${styles.responsiveNav}`)
  }
  return (
    <header>
      <nav ref={navRef} className={`${styles.navegacao}`}>
        <MenuLink onClick={openMenu} className={`${styles.links}`} to="/">
          Início
        </MenuLink>
        <MenuLink onClick={openMenu} className={`${styles.links}`} to="projetos">
          Projetos
        </MenuLink>
        <MenuLink onClick={openMenu} className={`${styles.links}`} to="habilidades">
          Skills
        </MenuLink>
        <MenuLink onClick={openMenu} className={`${styles.links}`} to="contato">
          Contato
        </MenuLink>
        <button className={`${styles.navbtn} ${styles.navbtnClose}`} onClick={openMenu}>
          <FaTimes />
        </button>
      </nav>
      <h3 className={styles.titulo}>
          {"Portfólio"}
        </h3>
      <button className={`${styles.navbtn} open`} onClick={openMenu}>
        <FaBars />
      </button>
    </header>
  );
}
