import PostCard from "componentes/PostCard";
import posts from "json/posts.json";
import styles from "./Projeto.module.css";
import AnimatedPage from "componentes/Animated/animatedPage";

export default function Projetos() {
  const projetos = posts.map((post) => {
    return (
      <li>
        <PostCard post={post}></PostCard>
      </li>
    );
  });
  return (
    <AnimatedPage>
      <h2 className={styles.titulo}>Projetos</h2>
      <ul className={styles.grid}>{projetos}</ul>
    </AnimatedPage>
  );
}
