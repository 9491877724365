import AnimatedPage from "componentes/Animated/animatedPage";
import styles from "./Contato.module.css";


export default function Contato(){
    return(
        <AnimatedPage>
            <div className={styles.card}>
                <h1>Contato</h1>
                <div>
                    <a className={styles.contato} href="https://github.com/ASLRafa">
                        <img src={"/assets/tags/github.png"} alt="icone do github"/>
                        <p>GitHub</p>
                    </a>
                    <a className={styles.contato} href="https://www.linkedin.com/in/rafasantosdev/" >
                        <img src={'/assets/tags/linkedin.png'} alt="icone do linkedin"/>
                        <p>LinkedIn</p>
                    </a>
                </div>
            </div>
        </AnimatedPage>
            )
}