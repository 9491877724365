import styles from "./DevTag.module.css";

export default function devTag({ tag }) {
  const tags = tag.map((tag) => {
    return (
      <li className={styles.tag}>
        <img
          className={styles.img}
          src={`/assets/tags/${tag}.png`}
          alt={tag}
        ></img>
      </li>
    );
  });
  return (
    <>
      <h3 className={styles.h3}>Skills:</h3>
      <ul className={styles.ul}>{tags}</ul>
    </>
  );
}
