import styles from "./Especialidade.module.css";

export default function Especialidade({ especialidade }) {
  const habilidades = especialidade.skills.map((skill) => {
    return (
      <li key={skill} className={styles.tag} alt={skill}>
        <img
          className={styles.img}
          src={`/assets/tags/${skill}.png`}
          alt={skill}
        />
        <h3 className={styles.legenda}>{skill}</h3>
      </li>
    );
  });
  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.titulo}>{especialidade.especialidade}</h2>
        <ul className={styles.tags}>{habilidades}</ul>
      </div>
    </>
  );
}
