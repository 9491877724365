import styles from "./Banner.module.css";
import minhaFoto from "assets/perfil_bg.png";
import maozinha from "assets/maozinha.svg";

export default function Banner() {
  return (
    <section className={styles.container__apresentacao}>
      <div className={styles.apresentacao__eu}>
        <div className={styles.apresentacao__ola}>
          <img src={maozinha} className={styles.ola__imagem} alt="mão acenando" />
          <h3 className={styles.ola__texto}>Olá,me chamo</h3>
        </div>
        <h2 className={styles.apresentacao__nome}>Rafael</h2>
        <h3 className={styles.apresentacao__funcao}>
          {"<> Web Developer </>"}
        </h3>
      </div>
      <img src={minhaFoto} className={styles.apresentacao__imagem} alt="foto do rafael santos desenvolvedor" />
    </section>
  );
}
