import { Route, Routes, useLocation } from "react-router-dom";
import Inicio from "./paginas/Inicio";
import Menu from "./componentes/Menu";
import Rodape from "componentes/Rodape";
import Post from "paginas/post";
import NaoEncontrada from "paginas/naoEncontrada";
import Projetos from "paginas/projetos";
import { AnimatePresence } from "framer-motion";
import Skills from "paginas/Skills";
import Contato from "paginas/contato";

function AppRoutes() {
  const location = useLocation();
  return (
    <>
      <Menu />
      <AnimatePresence mode="wait">
        <Routes key={location.pathname} location={location}>
          {/* Inicio */}
          <Route index element={<Inicio />} />
          {/* Projetos */}
          <Route path="projetos" element={<Projetos />} />
          {/* Skills */}
          <Route path="habilidades" element={<Skills />} />
          {/* Post */}
          <Route path="posts/:id/*" element={<Post />} />
          {/* Contato */}
          <Route path="contato" element={<Contato/>} />
          {/* Pagina não encontrada. */}
          <Route path="*" element={<NaoEncontrada></NaoEncontrada>} />
        </Routes>
      </AnimatePresence>
      <Rodape />
    </>
  );
}

export default AppRoutes;
