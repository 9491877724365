import AnimatedPage from "componentes/Animated/animatedPage";
import Banner from "componentes/Banner";

export default function Inicio() {
  return (
    <AnimatedPage>
      <Banner></Banner>
    </AnimatedPage>
  );
}
