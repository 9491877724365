import Especialidade from "componentes/Especialidade";
import styles from "./Skill.module.css";
import skills from "json/skills.json";
import AnimatedPage from "componentes/Animated/animatedPage";

export default function Skills() {
  const habilidades = skills.map((skill) => {
    return <Especialidade especialidade={skill} />;
  });
  return (
    <AnimatedPage>
      <h2 className={styles.titulo}>Habilidades</h2>
      <div className={styles.container}>{habilidades}</div>
    </AnimatedPage>
  );
}
